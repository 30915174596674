










import Main from '@/views/Home/Main.vue';
import Specialties from '@/views/Home/Specialties.vue';
import Testimonial from '@/views/Home/Testimonial.vue';
import Partners from '@/views/Home/Partners.vue';
import Footer from '@/views/Home/Footer.vue';
export default {
    name: 'Home',
    components: {
        Main,
        Specialties,
        Testimonial,
        Partners,
        Footer,
    },
};
