








export default {
    name: 'App',
    metaInfo: {
        title: 'Annuaire Résidences Services',
        // titleTemplate: '%s | Résidence Services',
        htmlAttrs: {
            lang: 'fr',
        },
        meta: [
            { charset: 'utf-8' },
            {
                name: 'description',
                content: 'An example Vue application with vue-meta.',
            },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'robots', content: 'index,follow' },
            { name: 'googlebot', content: 'index,follow' },
            { name: 'google', content: 'nositelinkssearchbox' },
        ],
    },
};
