































































// @ts-ignore
import Vue from 'vue';

export default Vue.extend({
    name: 'Specialties',
    data() {
        return {};
    },
    methods: {},
});
