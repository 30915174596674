import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';

Vue.use(VueMeta);

Vue.use(VueGtag, {
  // config: { id: 'GTM-P8BZ97J' },
  config: { id: 'AW-699900319' },
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
